<template>
  <div class="m-3" style="background-color: #f8fafb !important">
    <b-form-input
      id="input-1"
      v-model="botao.text"
      placeholder="Texto do botão"
      required
      maxlength="60"
    ></b-form-input>
  </div>
</template>

<script>
export default {
  name: "BotaoPersonalizado",
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      botao: this.value
    };
  },
  watch: {
    botao: {
      handler(novo) {
        this.$emit("value", novo);
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped></style>
