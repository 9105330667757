<template>
  <div class="d-flex m-3" style="background-color: #f8fafb !important">
    <b-row class="w-100">
      <div class="d-flex justify-content-between w-100">
        <div class="w-100 ml-2">
          <CascadeSelect
            v-model="botao.type"
            :options="opcoaoBotao"
            optionLabel="cname"
            optionValue="code"
            optionGroupLabel="name"
            @change="mudancaSelectPaisLink"
            :optionGroupChildren="['opcoes']"
            class="w-100"
          />
        </div>

        <div class="w-75 ml-2">
          <span class="p-float-label w-100">
            <InputText
              id="label"
              type="text"
              maxlength="25"
              class="w-100"
              v-model="botao.text"
              :disabled="value.type == 'COPY_CODE'"
            />
            <label for="label">Texto do botão</label>
          </span>
        </div>

        <div
          class="w-50 ml-2"
          v-if="!['COPY_CODE', 'PHONE_NUMBER'].includes(value.type)"
        >
          <CascadeSelect
            v-model="selectPaisLink"
            class="w-100"
            :options="opcoesSelect"
            :key="botaoAcaoKey"
            optionLabel="cname"
            optionValue="code"
            optionGroupLabel="name"
            :optionGroupChildren="['opcoes']"
            :disabled="value.type == 'PHONE_NUMBER'"
          />
        </div>

        <div class="d-flex align-items-center w-100 ml-2">
          <span class="p-float-label w-100">
            <InputNumber
              v-if="value.type == 'PHONE_NUMBER'"
              id="label"
              v-model="botao.phone_number"
              class="w-100"
            />

            <InputText
              v-if="value.type == 'URL'"
              id="label"
              type="text"
              v-model="botao.url"
              class="w-100"
              @blur="inserirVariavel"
            />
            <InputText
              v-if="value.type == 'COPY_CODE'"
              id="label"
              type="text"
              v-model="this.botao.example"
              class="w-100"
            />
            <label for="label">{{
              value.type == "PHONE_NUMBER"
                ? "Telefone"
                : value.type == "URL"
                ? "URL do site"
                : "Código de oferta"
            }}</label>
          </span>
          <span
            v-if="value.type == 'URL' && selectPaisLink == 'D'"
            class="ml-1"
            style="color: #7e7e7e"
            v-html="'{{1}}'"
          />
        </div>
      </div>
      <div
        class="d-flex flex-column w-100 ml-2 mt-6"
        v-if="botao.type == 'URL' && botao.example"
      >
        <span>Adicionar URL de amostra</span>

        <InputText
          class="mt-2"
          v-model="botao.example[0]"
          id="label"
          type="text"
          :placeholder="`Insira a URL completa para ${botao.url}`"
        />
      </div>
    </b-row>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputtext";
import CascadeSelect from "primevue/cascadeselect";
import InputMask from "primevue/inputmask";

export default {
  name: "BotaoAcao",
  components: {
    InputText,
    InputNumber,
    CascadeSelect
  },
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      botao: this.value,
      tipoBotao: this.value.type,
      selectPaisLink: this.value.select,
      botaoAcaoKey: 0,
      telefoneSite: "",
      opcoesPais: [
        {
          cname: "BR +55",
          code: "55"
        }
      ],
      opcoesLink: [
        {
          cname: "Estático",
          code: "E"
        },
        {
          cname: "Dinâmico",
          code: "D"
        }
      ],
      opcoaoBotao: [
        {
          cname: "Acessar site",
          code: "URL"
        },
        {
          cname: "Ligar",
          code: "PHONE_NUMBER"
        },
        {
          cname: "Copiar código da oferta",
          code: "COPY_CODE"
        }
      ]
    };
  },
  computed: {
    opcoesSelect() {
      if (this.botao.type == "PHONE_NUMBER") return this.opcoesPais;
      if (this.botao.type == "URL") return this.opcoesLink;
      return [];
    }
  },
  methods: {
    colocarPadraoPaisLink(valor) {
      if (valor == "PHONE_NUMBER") {
        this.botao.phone_number = "55";
      }
      if (this.botao.select == "" || this.tipoSelectErrado()) {
        this.selectPaisLink =
          this.value.type == "PHONE_NUMBER"
            ? "55"
            : this.value.type == "URL"
            ? "E"
            : "";
        this.reloadBotaoAcao();
      }
      this.selectPaisLink =
        this.botao.example && this.value.type == "URL" ? "D" : "E";
    },
    tipoSelectErrado() {
      return (
        (this.value.type == "URL" && /^\d+$/g.test(this.value.select)) ||
        (this.value.type == "PHONE_NUMBER" &&
          /^[A-Za-z\s]+$/.test(this.value.select))
      );
    },
    mudancaSelectPaisLink(novo) {
      this.colocarPadraoPaisLink();
      this.$forceUpdate();
    },
    reloadBotaoAcao() {
      this.botaoAcaoKey++;
    },
    inserirVariavel() {
      if (
        this.selectPaisLink == "D" &&
        this.botao.type == "URL" &&
        !this.botao.url.includes("{{1}}")
      ) {
        this.botao.url = `${this.botao.url}${
          this.botao.url.slice(-1) == "/" ? "{{1}}" : "/{{1}}"
        }`;
      }
    }
  },
  watch: {
    botao: {
      handler(novo) {
        if (novo.url && /{{\d+}}/g.test(novo.url)) {
          this.selectPaisLink = "D";
        }
        this.$emit("input", { ...this.botao, select: this.selectPaisLink });
        this.$forceUpdate();
      },
      deep: true,
      immediate: true
    },
    "botao.phone_number"(novo, antigo) {
      if (/^\+?[0-9]*$/.test(novo)) {
        this.botao.phone_number = novo;
      } else {
        this.botao.phone_number = antigo;
      }
    },
    "botao.url"(novo) {
      if (/{{(\d+)}}/g.test(novo) && this.botao.type == "URL") {
        this.botao.example = this.botao.example ?? [];
      }
    },
    selectPaisLink(novo) {
      if (novo == "D" && this.botao.type == "URL") {
        this.botao.example = this.botao.example ?? [];
      } else {
        delete this.botao.example;
      }
    }
  },
  mounted() {
    this.colocarPadraoPaisLink();
  }
};
</script>

<style scoped></style>
