<template>
  <b-col md="12" :key="templateKey">
    <div
      v-for="(row, key) in rows"
      :key="key"
      class="p-5 w-100"
      style="margin: 10px 10px"
    >
      <span class="mb-2">{{
        row.some((i) => i.type == "QUICK_REPLY")
          ? "Resposta rápida"
          : "Chamada para ação"
      }}</span>
      <draggable
        class="list-group"
        tag="ul"
        v-model="rows[key]"
        :group="`group-${key}`"
        v-bind="dragOptions"
        @start="drag = true"
        @end="draggableEnd"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <li
            class="list-group-item campo-personalizado my-3 d-flex align-items-center w-100"
            v-for="(element, key2) in rows[key]"
            :key="key2"
          >
            <i
              :class="
                element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
              "
              @click="element.fixed = !element.fixed"
              aria-hidden="true"
            ></i>
            <BotaoAcao
              v-if="rows[key][key2].type != 'QUICK_REPLY'"
              v-model="rows[key][key2]"
              class="w-100"
            />
            <BotaoPersonalizado
              v-else
              v-model="rows[key][key2]"
              class="w-100"
            />
            <b-icon
              @click="remover(rows[key][key2], key)"
              class="h3"
              icon="x-circle"
              variant="danger"
              style="cursor: pointer"
            ></b-icon>
          </li>
        </transition-group>
      </draggable>
    </div>
  </b-col>
</template>

<script>
import draggable from "vuedraggable";
import BotaoPersonalizado from "./BotaoPersonalizado.vue";
import BotaoAcao from "./BotaoAcao.vue";

export default {
  name: "DraggableBottons",
  props: ["value"],
  components: {
    draggable,
    BotaoPersonalizado,
    BotaoAcao
  },
  data() {
    return {
      drag: false,
      buttons: this.value.buttons,
      enabled: true,
      rows: [],
      templateKey: 0
    };
  },
  methods: {
    remover(button, rowKey) {
      this.rows[rowKey] = this.rows[rowKey].filter((i) => i != button);
      this.rows = this.rows.filter((r) => r.length != 0);

      this.updateValue();
      // this.$forceUpdate();
    },
    updateValue() {
      let listValue = [];
      this.rows.forEach((r) => {
        r.forEach((b) => {
          listValue.push(b);
        });
      });
      this.buttons = listValue;
      this.$emit("input", { buttons: this.buttons, type: this.value.type });
      this.$forceUpdate();
    },
    reloadTemplate() {
      this.templateKey++;
    },
    draggableEnd() {
      this.drag = false;
      this.reloadTemplate();
      this.updateValue();
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        // group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  watch: {
    buttons: {
      handler(buttons) {
        if (buttons) {
          let groups = {
            QUICK_REPLY: 0,
            URL: 1,
            PHONE_NUMBER: 1,
            COPY_CODE: 1
          };
          let typeInit = "";
          if (this.rows[0]) this.rows[0] = [];
          if (this.rows[1]) this.rows[1] = [];
          buttons.forEach((element, key) => {
            if (key == 0) {
              if (!this.rows[0]) this.rows.push([]);
              this.rows[0].push(element);
              typeInit = element.type;
            } else if (groups[typeInit] == groups[element.type]) {
              this.rows[0].push(element);
            } else {
              if (!this.rows[1]) this.rows.push([]);
              this.rows[1].push(element);
            }
          });
          this.$forceUpdate();
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style>
.buttons {
  margin-top: 35px;
}

.row-v {
  height: 150px;
  width: 200px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
