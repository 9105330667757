<template>
  <b-card>
    <ModalConfirmacaoSair />
    <div class="d-flex justify-content-between w-100">
      <div class="back-arrow-squared" @click="showModalConfirmacaoSair">
        <i class="flaticon-up-arrow" style="color: #808080 !important"></i>
      </div>
      <div class="d-flex justify-content-end mr-3">
        <div class="col-6 w-100">
          <section class="section-data">
            <b-form-select
              v-model="conta"
              :options="subcontas"
              :disabled="
                currentUserPersonalInfo.conta_id != contaPrincipalId || edicao()
              "
              min="1"
              max="24"
              class="col"
            ></b-form-select>
          </section>
        </div>
        <b-button variant="success" @click="submit" class="text-uppercase"
          >Salvar Template</b-button
        >
      </div>
    </div>
    <b-row>
      <b-col md="8">
        <b-form>
          <b-row>
            <b-col>
              <div class="field w-100 mt-1">
                <h5 style="font-size: 0.98rem">Nome do modelo</h5>
                <InputText
                  aria-describedby="username2-help"
                  v-model="template.name"
                  class="w-100"
                  :class="{ 'p-invalid': validate.nomeModelo }"
                  maxlength="512"
                  @input="validateInput"
                  :disabled="edicao()"
                />
              </div>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-2"
                label="Categoria"
                label-for="input-2"
              >
                <CascadeSelect
                  v-model="template.category"
                  :options="form.opcoesCategoria"
                  optionLabel="cname"
                  optionValue="code"
                  optionGroupLabel="name"
                  @change="mudancaCategoria"
                  class="w-100"
                  :class="{ 'p-invalid': validate.categoria }"
                  :disabled="edicao()"
                  :optionGroupChildren="['opcoes']"
                  style="minwidth: 14rem"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-3"
                label="Idioma"
                label-for="input-3"
              >
                <MultiSelect
                  id="input-3"
                  v-model="templateLanguage"
                  :options="form.opcoesIdioma"
                  optionLabel="cname"
                  optionValue="code"
                  disabled
                  required
                  class="w-100"
                ></MultiSelect>
              </b-form-group>
            </b-col>
          </b-row>
          <template v-if="template.category != 'AUTHENTICATION'">
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-2"
                  label="Cabeçalho"
                  label-for="input-2"
                  description=""
                >
                  <CascadeSelect
                    v-model="cabecalho.format"
                    :options="form.cabecalho.opcoes"
                    @change="mudancaTipoCabecalho"
                    :optionGroupChildren="['opcoes']"
                    optionLabel="cname"
                    optionValue="code"
                    optionGroupLabel="name"
                    class="w-25"
                    style="minwidth: 14rem"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col>
                <b-form-group
                  v-if="cabecalho.format == 'TEXT'"
                  label="Texto do cabeçalho"
                >
                  <b-form-input
                    id="input-1"
                    v-model="cabecalho.text"
                    placeholder="Insira o texto"
                    required
                    @input="monitorCabecalhoTexto"
                    maxlength="60"
                  ></b-form-input>

                  <div
                    class="campo-exemplo mt-4"
                    v-if="/{{\d+}}/g.test(cabecalho.text) && cabecalho.example"
                  >
                    <span>Exemplo de conteúdo do cabeçalho</span>

                    <div
                      class="my-2"
                      v-for="(v, key) in cabecalho.example.header_text"
                      :key="key"
                    >
                      <div class="row">
                        <div
                          class="col-1 d-flex align-items-center"
                          v-html="`{{${key + 1}}}`"
                        />

                        <div class="col-11">
                          <b-form-input
                            v-model="cabecalho.example.header_text[key]"
                            :placeholder="`Insira um valor para {{${key + 1}}}`"
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form-group>

                <div
                  v-if="
                    ['VIDEO', 'IMAGE', 'DOCUMENT'].includes(cabecalho.format)
                  "
                  class="campo-exemplo"
                >
                  <b-form-group
                    label="Exemplo de conteúdo de cabeçalho"
                    v-if="cabecalho.example"
                  >
                    <div class="d-flex align-items-center">
                      <b-form-input
                        id="input-1"
                        v-model="cabecalho.example.header_handle[0]"
                        placeholder="URL Imagem/Video/Documento"
                      ></b-form-input>

                      <button
                        class="btn btn-icon btn-light-primary ml-2"
                        type="button"
                        @click="uploadArquivo"
                        v-b-tooltip.noninteractive.hover.left
                        :title="`Enviar ${
                          cabecalho.format == 'IMAGE'
                            ? 'imagem'
                            : cabecalho.format == 'VIDEO'
                            ? 'vídeo'
                            : 'documento'
                        }`"
                        style="background-color: #ff7f00"
                      >
                        <i
                          class="fa-solid"
                          :class="
                            cabecalho.format == 'IMAGE'
                              ? 'fa-camera'
                              : cabecalho.format == 'VIDEO'
                              ? 'fa-video'
                              : 'fa-file'
                          "
                          style="color: white"
                        ></i>
                      </button>
                    </div>
                    <p class="mt-1">
                      Ao enviar o arquivo, o conteúdo pode não aparecer no
                      preview ou mostrar como um link quebrado. Ao salvar o
                      template e visualizar na edição, o conteúdo aparecerá
                      normalmente.
                    </p>
                    <input
                      ref="inputFile"
                      type="file"
                      :accept="
                        cabecalho.format == 'IMAGE'
                          ? 'image/jpg, image/jpeg, image/png'
                          : cabecalho.format == 'VIDEO'
                          ? 'video/mp4'
                          : 'application/pdf'
                      "
                      style="visibility: hidden; width: 0px"
                    />
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group
                  id="input-group-4"
                  label="Corpo"
                  label-for="input-4"
                  description=""
                >
                  <b-form-textarea
                    id="input-4"
                    v-model="corpo.text"
                    placeholder="Insira o texto"
                    rows="10"
                    no-resize
                    @input="monitorCorpoTexto"
                    maxlength="1024"
                  ></b-form-textarea>
                  <span class="mt-2"
                    >Caracteres:
                    {{ corpo.text ? corpo.text.length : 0 }}/1024</span
                  >
                  <div
                    class="campo-exemplo mt-4"
                    v-if="/{{\d+}}/g.test(corpo.text)"
                  >
                    <span>Exemplo de conteúdo do corpo</span>

                    <div
                      class="my-2"
                      v-for="(v, key) in corpo.example.body_text[0]"
                      :key="key"
                    >
                      <div class="row">
                        <div
                          class="col-1 d-flex align-items-center"
                          v-html="`{{${key + 1}}}`"
                        />

                        <div class="col-11">
                          <b-form-input
                            v-model="corpo.example.body_text[0][key]"
                            @input="() => previewKey++"
                            :placeholder="`Insira um valor para {{${key + 1}}}`"
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-5"
                  label="Rodapé"
                  label-for="input-5"
                  description=""
                >
                  <b-form-input
                    id="input-1"
                    v-model="rodape.text"
                    placeholder="Insira o texto"
                    required
                    maxlength="60"
                  ></b-form-input>
                  <span class="my-2"
                    >Caracteres:
                    {{ rodape.text ? rodape.text.length : 0 }}/60</span
                  >
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-5"
                  label="Botões"
                  label-for="input-5"
                  description=""
                >
                  <b-dropdown
                    id="dropdown-text"
                    text="Adicione um botão"
                    class="m-2"
                  >
                    <b-dropdown-header id="dropdown-header-label">
                      Botões de resposta rápida
                    </b-dropdown-header>
                    <b-dropdown-item-button @click="addBotaoPersonalizado(null)"
                      >Personalizado</b-dropdown-item-button
                    >
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-header id="dropdown-header-label">
                      Botões de chamada para ação
                    </b-dropdown-header>
                    <b-dropdown-item-button
                      @click="addBotaoAcao('URL')"
                      :disabled="botaoDesativado('URL')"
                    >
                      Acessar site <br />
                      <span style="font-size: 0.8rem">2 botões no máximo</span>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      @click="addBotaoAcao('PHONE_NUMBER')"
                      :disabled="botaoDesativado('PHONE_NUMBER')"
                    >
                      Ligar <br />
                      <span style="font-size: 0.8rem">
                        1 botão no máximo</span
                      ></b-dropdown-item-button
                    >
                    <b-dropdown-item-button
                      @click="addBotaoAcao('COPY_CODE')"
                      :disabled="botaoDesativado('COPY_CODE')"
                      >Copiar código da oferta <br />
                      <span style="font-size: 0.8rem">
                        1 botão no máximo</span
                      ></b-dropdown-item-button
                    >
                  </b-dropdown>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <DraggableBottons :key="draggableKey" v-model="botoes" />
            </b-row>
          </template>
          <template v-else>
            <div style="min-height: 500px">
              <h5 class="mt-3">Conteúdo da mensagem</h5>
              <p style="font-size: 0.95rem; color: #a0a0a0">
                Não é possível editar o conteúdo dos modelos de mensagem de
                autenticação. Você pode adicionar mais conteúdo das opções
                abaixo.
              </p>
              <div class="field-checkbox d-flex m-1">
                <Checkbox
                  id="city1"
                  name="city"
                  value="msgSeguranca"
                  @change="mensagensAutenticacao"
                  v-model="msgAutenticacao"
                />
                <label for="city1" class="ml-2"
                  >Adicionar recomendação de segurança</label
                >
              </div>
              <div class="field-checkbox d-flex m-1">
                <Checkbox
                  id="city2"
                  name="city"
                  value="msgTempo"
                  @change="mensagensAutenticacao"
                  v-model="msgAutenticacao"
                />
                <label for="city2" class="ml-2"
                  >Adicione o tempo de expiração do código</label
                >
              </div>
              <div
                class="campo-exemplo w-50 mt-3"
                v-if="msgAutenticacao.includes('msgTempo')"
              >
                <span>Expira em</span>
                <b-form-input
                  v-model="valorTempo"
                  type="number"
                  value="10"
                  :min="0"
                  :max="90"
                  @input="entradaTempoMensagem"
                  style="width: 150px"
                ></b-form-input>
              </div>
              <div class="mt-5">
                <h5 class="mb-3">Texto do botão</h5>
                <p style="font-size: 0.95rem; color: #a0a0a0">
                  É possível personalizar o texto do botão para "Preencher
                  automaticamente" e "Copiar código". Mesmo quando o toque zero
                  estiver ativado, os botões ainda serão necessários para o
                  método de entrega de código de reserva.
                </p>
                <span class="p-float-label mt-5">
                  <InputText
                    id="textoBotao"
                    type="text"
                    v-model="this.botoes.buttons[0].text"
                  />
                  <label for="textoBotao">Copiar código</label>
                </span>
              </div>
            </div>
          </template>
        </b-form>
      </b-col>
      <b-col md="4" class="mt-3">
        <PreviewWhatsapp
          :key="previewKey"
          @click="() => previewKey++"
          v-model="template.components"
          style="width: 95%"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import MultiSelect from "primevue/multiselect";
import InputText from "primevue/inputtext";
import { mapGetters } from "vuex";
import CascadeSelect from "primevue/cascadeselect";
import ModalConfirmacaoSair from "../campanha/cadastro-componentes/ModalConfirmacaoSair.vue";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import PreviewWhatsapp from "./PreviewWhatsapp.vue";
import DraggableBottons from "./DraggableBottons.vue";
import Checkbox from "primevue/checkbox";

export default {
  name: "CadastroTemplateWhatsapp",
  props: {
    value: {
      type: Object
    }
  },
  components: {
    MultiSelect,
    CascadeSelect,
    ModalConfirmacaoSair,
    PreviewWhatsapp,
    DraggableBottons,
    InputText,
    // InputNumber,
    Checkbox
  },
  data() {
    return {
      contaPrincipalId: null,
      variaveisCorpo: 0,
      previewKey: 0,
      draggableKey: 0,
      template: {
        name: "",
        allow_category_change: true, // Permite que o facebook mude a categoria caso eles achem que a categoria está errada
        components: [
          {
            type: "HEADER",
            format: "Nenhum"
          },
          {
            type: "BODY",
            text: ""
          },
          {
            type: "FOOTER",
            text: ""
          }
        ],
        language: "pt_BR",
        category: ""
      },
      validate: {
        nomeModelo: false,
        categoria: false
      },
      msgAutenticacao: [],
      templateComponentes: [
        {
          type: "HEADER",
          format: "IMAGE",
          example: {
            header_handle: [
              "https://scontent.whatsapp.net/v/t61.29466-34/355628727_1033167051005201_6647403490678010833_n.png?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=evKv3etAHTIAX-tAPPf&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&oh=01_AdSAZbmylamWmXO0PbiYyhoIhuel0dKKVWT-L76jN1h7IA&oe=654E5741"
            ]
          }
        },
        {
          type: "BODY",
          text: "Olá, *{{1}},* {{2}}!\n\nAgradecemos por escolher a *Wellon* 🧡💙\n\nPara mantermos a conexão com mais pessoas a cada dia, queremos saber sobre a sua experiência conosco. Leva menos de 1 minuto, é só clicar no link *{{3}}* 🚀 ou responder com uma nota de 0 a 10.\n\nAbraços. _Equipe Wellon_",
          example: {
            body_text: [["Caroline", "bom dia", "https://diz.vc/1679/LSPw"]]
          }
        },
        {
          type: "FOOTER",
          text: "Tecnologia Wellon Digital"
        },
        {
          type: "BUTTONS",
          buttons: [
            {
              type: "URL",
              text: "Responder pesquisa",
              url: "https://www.diz.vc/1679/{{1}}",
              example: ["https://www.diz.vc/1679/1VOo"]
            }
          ]
        }
      ],
      form: {
        idioma: ["pt_BR"],
        opcoesIdioma: [
          {
            cname: "Africâner",
            code: "af"
          },
          {
            cname: "Albanês",
            code: "sq"
          },
          {
            cname: "Árabe",
            code: "ar"
          },
          {
            cname: "Azerbaijano",
            code: "az"
          },
          {
            cname: "Bengalês",
            code: "bn"
          },
          {
            cname: "Búlgaro",
            code: "bg"
          },
          {
            cname: "Catalão",
            code: "ca"
          },
          {
            cname: "Chinês (CHN)",
            code: "zh_CN"
          },
          {
            cname: "Chinês (HKG)",
            code: "zh_HK"
          },
          {
            cname: "Chinês (TAI)",
            code: "zh_TW"
          },
          {
            cname: "Croata",
            code: "hr"
          },
          {
            cname: "Tcheco",
            code: "cs"
          },
          {
            cname: "Dinamarquês",
            code: "da"
          },
          {
            cname: "Holandês",
            code: "nl"
          },
          {
            cname: "Inglês",
            code: "en"
          },
          {
            cname: "Inglês (Reino Unido)",
            code: "en_GB"
          },
          {
            cname: "Inglês (EUA)",
            code: "en_US"
          },
          {
            cname: "Português (BR)",
            code: "pt_BR"
          },
          {
            cname: "Estoniano",
            code: "et"
          },
          {
            cname: "Filipino",
            code: "fil"
          },
          {
            cname: "Finlandês",
            code: "fi"
          },
          {
            cname: "Francês",
            code: "fr"
          },
          {
            cname: "Alemão",
            code: "de"
          },
          {
            cname: "Grego",
            code: "el"
          },
          {
            cname: "Guzerate",
            code: "gu"
          },
          {
            cname: "Hauçá",
            code: "ha"
          },
          {
            cname: "Hebraico",
            code: "he"
          },
          {
            cname: "Hindi",
            code: "hi"
          },
          {
            cname: "Húngaro",
            code: "hu"
          },
          {
            cname: "Indonésio",
            code: "id"
          },
          {
            cname: "Irlandês",
            code: "ga"
          },
          {
            cname: "Italiano",
            code: "it"
          },
          {
            cname: "Japonês",
            code: "ja"
          },
          {
            cname: "Canarês",
            code: "kn"
          },
          {
            cname: "Cazaque",
            code: "kk"
          },
          {
            cname: "Coreano",
            code: "ko"
          },
          {
            cname: "Laociano",
            code: "lo"
          },
          {
            cname: "Letão",
            code: "lv"
          },
          {
            cname: "Lituano",
            code: "lt"
          },
          {
            cname: "Macedônio",
            code: "mk"
          },
          {
            cname: "Malaio",
            code: "ms"
          },
          {
            cname: "Malaiala",
            code: "ml"
          },
          {
            cname: "Marati",
            code: "mr"
          },
          {
            cname: "Norueguês",
            code: "nb"
          },
          {
            cname: "Persa",
            code: "fa"
          },
          {
            cname: "Polonês",
            code: "pl"
          },
          {
            cname: "Português (POR)",
            code: "pt_PT"
          },
          {
            cname: "Punjabi",
            code: "pa"
          },
          {
            cname: "Romeno",
            code: "ro"
          },
          {
            cname: "Russo",
            code: "ru"
          },
          {
            cname: "Sérvio",
            code: "sr"
          },
          {
            cname: "Eslovaco",
            code: "sk"
          },
          {
            cname: "Esloveno",
            code: "sl"
          },
          {
            cname: "Espanhol",
            code: "es"
          },
          {
            cname: "Espanhol (ARG)",
            code: "es_AR"
          },
          {
            cname: "Espanhol (ESP)",
            code: "es_ES"
          },
          {
            cname: "Espanhol (MEX)",
            code: "es_MX"
          },
          {
            cname: "Suaíli",
            code: "sw"
          },
          {
            cname: "Sueco",
            code: "sv"
          },
          {
            cname: "Tâmil",
            code: "ta"
          },
          {
            cname: "Telugo",
            code: "te"
          },
          {
            cname: "Tailandês",
            code: "th"
          },
          {
            cname: "Turco",
            code: "tr"
          },
          {
            cname: "Ucraniano",
            code: "uk"
          },
          {
            cname: "Urdu",
            code: "ur"
          },
          {
            cname: "Uzbeque",
            code: "uz"
          },
          {
            cname: "Vietnamita",
            code: "vi"
          },
          {
            cname: "Zulu",
            code: "zu"
          }
        ],
        opcoesCategoria: [
          {
            cname: "Marketing",
            code: "MARKETING"
          },
          {
            cname: "Utilidade",
            code: "UTILITY"
          },
          {
            cname: "Autenticação",
            code: "AUTHENTICATION"
          }
        ],
        cabecalho: {
          tipo: "Nenhum",
          texto: "",
          opcoes: [
            {
              cname: "Nenhum",
              code: "Nenhum"
            },
            {
              cname: "Texto",
              code: "TEXT"
            },
            {
              name: "Mídia",
              code: "Mídia",
              opcoes: [
                {
                  cname: "Imagem",
                  code: "IMAGE"
                },
                {
                  cname: "Vídeo",
                  code: "VIDEO"
                },
                {
                  cname: "Documento",
                  code: "DOCUMENT"
                },
                {
                  cname: "Localização",
                  code: "LOCATION"
                }
              ]
            }
          ],
          midia: {}
        },
        corpo: {
          texto: "",
          variable: []
        },
        rodape: {
          texto: ""
        },
        botoes: {
          personalizados: [],
          acao: []
        }
      },
      subcontas: [],
      conta: null,
      drag: false,
      quantidateBotoes: {
        URL: 2,
        PHONE_NUMBER: 1,
        COPY_CODE: 1
      }
    };
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    templateLanguage: {
      get() {
        return Array.isArray(this.template.language)
          ? this.template.language
          : [this.template.language];
      },
      set(valor) {
        if (
          Array.isArray(this.template.language) &&
          this.template.language.length == 1
        ) {
          this.template.language = valor[0];
        }
        this.template.language = valor;
      }
    },
    cabecalho: {
      get() {
        return (
          this.template.components.find((i) => i.type == "HEADER") || {
            format: "Nenhum"
          }
        );
      },
      set(valor) {
        let itemIndex = this.template.components.findIndex(
          (i) => i.type == "HEADER"
        );
        if (itemIndex != -1) {
          this.template.components[itemIndex] = valor;
        }
      },
      cache: false
    },
    corpo() {
      return this.template.components.find((i) => i.type == "BODY") || {};
    },
    rodape() {
      return this.template.components.find((i) => i.type == "FOOTER") || {};
    },
    botoes: {
      get() {
        let bts =
          this.template.components.find((i) => i.type == "BUTTONS") || {};
        return bts;
      },
      set(objBotao) {
        this.$nextTick(() => {
          if (objBotao.buttons.length) {
            let indexButtons = this.template.components.findIndex(
              (i) => i.type == "BUTTONS"
            );
            if (indexButtons != -1) {
              this.template.components[indexButtons] = objBotao;
            } else {
              this.template.components.push(objBotao);
            }
          } else {
            this.template.components = this.template.components.filter(
              (i) => i.type != "BUTTONS"
            );
          }
          this.$forceUpdate();
          this.reloadPreview();
          this.reloadDraggable();
        });
      },
      cache: false
    }
  },
  methods: {
    carregarTemplate() {
      const temp = {
        name: "",
        components: [],
        language: "",
        category: "UTILITY"
      };
      return this.value ?? temp;
    },
    mudancaTipoCabecalho(valor) {
      this.template.components = this.template.components.filter((i) => {
        if (i.type != "HEADER") return i;
      });

      let o = {
        type: "HEADER",
        format: valor.value
      };

      switch (valor.value) {
        case "TEXT":
          o.text = "";
          break;
        case "IMAGE":
        case "VIDEO":
        case "DOCUMENT":
          o.example = {};
          o.example.header_handle = [""];
          break;
        default:
          delete this.cabecalho.example;
          break;
      }

      this.template.components.push(o);
    },
    validateInput(event) {
      this.$nextTick(() => {
        this.template.name = event
          .replace(" ", "_")
          .toLowerCase()
          .replace(/[^a-zA-Z0-9_]/g, "");
      });
    },
    submit() {
      if (!this.validar()) return;
      const data = this.montarPayload();
      let params = this.conta != null ? `?conta_id=${this.conta}` : "";
      if (this.edicao()) {
        ApiService.patch(`template${params}`, data).then((response) => {
          let msg = "";
          const responseCategory = response.data.data?.category;
          if (responseCategory && data.category != responseCategory) {
            msg = ` Porém a categoria do template foi alterada automaticamente pelo META para '${responseCategory}'`;
          }
          this.mostrarSwal({ text: "Editado com sucesso!" + msg });
        });
      } else {
        ApiService.post(`template${params}`, data).then((response) => {
          let msg = "";
          const responseCategory = response.data.data?.category;
          if (responseCategory && data.category != responseCategory) {
            msg = ` Porém a categoria do template foi alterada automaticamente pelo META para '${responseCategory}'`;
          }
          this.mostrarSwal({ text: "Salvo com sucesso!" + msg });
        });
      }
    },
    mensagensAutenticacao() {
      if (this.msgAutenticacao.includes("msgSeguranca")) {
        this.corpo.text = `Seu código de verificação é *{{1}}*. Para sua segurança, não compartilhe.`;
      } else {
        this.corpo.text = this.corpo.text.replace(
          "Para sua segurança, não compartilhe.",
          ""
        );
      }

      this.rodape.text = this.msgAutenticacao.includes("msgTempo")
        ? `Este código expira em 10 minutos.`
        : "";
    },
    entradaTempoMensagem(v) {
      let valor = v;
      if (v > 90) valor = 90;
      if (v < 1) valor = 1;
      this.valorTempo = valor;
      this.rodape.text = `Este código expira em ${valor} minutos.`;
    },
    validar() {
      this.validate.nomeModelo = this.template.name.trim() == "";
      this.validate.categoria = this.template.category.trim() == "";

      return !Object.keys(this.validate).some((key) => this.validate[key]);
    },
    mudancaCategoria(valor) {
      this.corpo.text =
        valor.value == "AUTHENTICATION"
          ? "Seu código de verificação é *{{1}}*."
          : "";
      if (valor.value == "AUTHENTICATION") {
        if (!this.botoes.buttons || !this.botoes.buttons.length) {
          this.addBotaoPersonalizado({
            type: "OTP",
            otp_type: "COPY_CODE",
            text: "Copiar código"
          });
        }
      } else {
        this.botoes = { buttons: [] };
      }
    },
    mostrarSwal(prop) {
      Swal.fire({
        title: prop?.title || "MENSAGEM",
        text: prop?.text || "Salvo com sucesso!",
        icon: prop?.icon || "success",
        heightAuto: false
      }).then((r) => {
        if (r.isConfirmed) {
          this.$router.push({
            name: "automacao",
            params: {
              navSelecionada: 6
            }
          });
        }
      });
    },
    showModalConfirmacaoSair() {
      // this.$router.push({
      //   name: "automacao",
      //   params: {
      //     navSelecionada: 6
      //   }
      // });
      this.$modal.show("modal-confirmacao-sair");
    },
    addBotaoPersonalizado(btn = null) {
      const b = btn ?? {
        type: "QUICK_REPLY",
        text: ""
      };
      if (!this.botoes || !Object.keys(this.botoes).length) {
        this.botoes = { type: "BUTTONS", buttons: [b] };
      } else {
        this.botoes.buttons.push(b);
      }
      this.$forceUpdate();
      // this.reloadPreview();
    },
    addBotaoAcao(tipo) {
      let botao = {
        type: tipo
      };

      switch (tipo) {
        case "URL":
          botao.text = "";
          botao.url = "";
          break;
        case "PHONE_NUMBER":
          botao.text = "";
          botao.phone_number = "";
          break;
        case "COPY_CODE":
          botao.example = "";
          break;
        default:
          break;
      }

      if (!this.botoes || !Object.keys(this.botoes).length) {
        this.botoes = { type: "BUTTONS", buttons: [botao] };
      } else {
        this.botoes.buttons.push(botao);
      }

      this.$forceUpdate();
      // this.reloadPreview();
    },
    montarPayload() {
      if (this.template.category == "AUTHENTICATION") {
        const t = this.template;
        return {
          name: t.name,
          languages: [t.language],
          category: t.category,
          components: [
            {
              type: "BODY",
              add_security_recommendation:
                this.msgAutenticacao.includes("msgSeguranca")
            },
            {
              type: "FOOTER",
              code_expiration_minutes: this.msgAutenticacao.includes("msgTempo")
            },
            {
              type: "BUTTONS",
              buttons: [
                {
                  type: "OTP",
                  otp_type: "COPY_CODE"
                }
              ]
            }
          ]
        };
      }
      let data = this.template.components
        .map((comp) => {
          switch (comp.type) {
            case "HEADER":
              if (comp.format != "Nenhum") return comp;
              break;
            case "FOOTER":
              if (comp.text != "") return comp;
              break;
            case "BUTTONS":
              comp.buttons.map((b) => {
                delete b.select;
                if (b.type == "PHONE_NUMBER") {
                  b.phone_number =
                    b.phone_number.length == 8
                      ? `55759${b.phone_number}` //Caso o numero tenha 8 digito
                      : b.phone_number.length == 9
                      ? `5575${b.phone_number}` //Caso o numero tenha 9 digito
                      : b.phone_number.length == 11
                      ? `55${b.phone_number}` //Caso o numero tenha 11 digito
                      : b.phone_number;
                }
              });
              return comp;
            default:
              return comp;
          }
        })
        .filter((i) => i);
      const v = { ...this.template };
      v.components = data;
      return v;
    },
    variaveis(texto) {
      let match;
      match = /{{(\d+)}}/g.exec(texto);
      // while ((match = /{{(\d+)}}/g.exec(texto)) !== null) {
      //   this.variaveis.push(parseInt())
      // }
    },
    monitorCorpoTexto(texto) {
      this.$nextTick(() => {
        texto = texto.replace(/(?<!{){(\d+)}(?!})/g, "{{$1}}");
        this.corpo.text = texto;

        let quantVariavel = (texto.match(/{{(\d+)}}/g) || []).length;
        if (quantVariavel > 0) {
          this.corpo.example = this.corpo.example ?? {};
          this.corpo.example.body_text = this.corpo.example.body_text ?? [[]];
          let resul = this.corpo.example.body_text[0].length - quantVariavel;
          if (resul < 0) {
            this.corpo.example.body_text[0].push("");
          }
          if (resul > 0) {
            this.corpo.example.body_text[0] =
              this.corpo.example.body_text[0].slice(0, quantVariavel);
          }
        } else {
          delete this.corpo.example;
        }
        this.$forceUpdate();
      });
    },
    monitorCabecalhoTexto(texto) {
      let temVariavel = /{{(\d+)}}/g.test(texto);
      if (temVariavel) {
        this.cabecalho.example = this.cabecalho.example || {};
        this.cabecalho.example.header_text =
          this.cabecalho.example.header_text || [];
        if (this.cabecalho.example.header_text.length < 1) {
          this.cabecalho.example.header_text.push("");
        }
      } else {
        delete this.cabecalho.example;
      }
      this.$forceUpdate();
    },
    inserirVariavel() {
      this.variaveisCorpo++;
      this.corpo.text += `{{${this.variaveisCorpo}}}`;
      this.$forceUpdate();
    },
    generateUID() {
      // I generate the UID from two parts here
      // to ensure the random number provide enough bits.
      let firstPart = (Math.random() * 46656) | 0;
      let secondPart = (Math.random() * 46656) | 0;
      firstPart = ("000" + firstPart.toString(36)).slice(-3);
      secondPart = ("000" + secondPart.toString(36)).slice(-3);
      return firstPart + secondPart;
    },
    edicao() {
      return this.$route.params.templateObj ? true : false;
    },
    reloadPreview() {
      this.previewKey++;
    },
    reloadDraggable() {
      this.draggableKey++;
    },
    botaoDesativado(tipo) {
      if (!this.botoes.buttons) return false;
      let quant = this.botoes.buttons.filter((i) => i.type == tipo).length;
      return quant > -1 ? this.quantidateBotoes[tipo] <= quant : true;
    },
    async init() {
      const subcontas = await ApiService.get(
        "/administrativo/conta",
        "",
        false
      );
      this.subcontas = subcontas.data.subContas.map((v) => {
        return {
          text: v.con_nome_identificacao ?? v.con_login,
          value: v.conta_id
        };
      });
      const contaPrincipalId = subcontas.data?.contaPrincipal?.conta_id;
      this.contaPrincipalId = contaPrincipalId;

      this.subcontas.unshift({
        value: contaPrincipalId,
        text: "Conta Principal"
      });

      if (contaPrincipalId != this.currentUserPersonalInfo.conta_id) {
        this.subcontas.push({
          value: this.currentUserPersonalInfo.conta_id,
          text: this.currentUserPersonalInfo.conta
        });
      }
      this.conta =
        this.$route.params.conta?.value ??
        this.currentUserPersonalInfo.conta_id;
    },
    uploadArquivo() {
      this.$refs.inputFile.addEventListener("change", this.uploadFile);
      this.$refs.inputFile.click();
    },

    uploadFile(event) {
      const file = event.target.files[0];

      let formData = new FormData();

      formData.append("file", file);

      ApiService.post("conta/upload-file-whatsapp", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then((response) => {
          this.cabecalho.example.header_handle[0] = response.data.data;
          this.$forceUpdate();
        })
        .catch(() => {
          Swal.fire({
            title: "MENSAGEM",
            text: "Erro ao fazer upload de arquivo!",
            icon: "error",
            heightAuto: false
          });
        });
    }
  },
  mounted() {
    this.init();
    this.$getFeatureFlag(4).then((response) => {
      if (!response.ffl_ativo) {
        this.$router.push({
          name: "automacao",
          params: {
            navSelecionada: 0
          }
        });
      }
    });
    if (this.$route.params.templateObj) {
      this.template = this.$route.params.templateObj;
    }
    this.reloadDraggable();
  }
};
</script>

<style scoped>
.campo-exemplo {
  /* background-color: #eef0f8; */
  background-color: #3c87f127;
  border: 1.5px solid #4787db;
  /* background-color: #f07b3d; */
  border-radius: 10px;
  padding: 18px 18px;
}

.campo-personalizado {
  /* border: 1px solid #d9d9d9; */
  border: 1px solid #f07b3d;
  border-radius: 5px;
}
</style>
